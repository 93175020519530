export const TRANSLATION_KEYS = {
	DB_LABELS: 'dbLabels',
	CLIENT_LABELS: 'clientLabels',
};
export const SERVICES_SHORT_TRANSLATION_KEY = 'short';
// for the moment we have special needs only on 'others'
export enum SERVICES_GROUPS {
	OTHERS = 'others',
}

export enum RESET_SELECTED_ROUTES {
	VIEW_CLAIMS = '/view-claims',
}

export const TEMP_SERVICES = [
	{
		code: 'exam',
		label: 'service.exam',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'lens',
		label: 'service.lens',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'frame',
		label: 'service.frame',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'contacts',
		label: 'service.contacts',
		group: {
			code: '',
			label: '',
		},
	},
	{
		code: 'fitting',
		label: 'service.fitting',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'warranty',
		label: 'service.warranty',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'second-pair',
		label: 'service.second-pair',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'cleaning-kit',
		label: 'service.cleaning-kit',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'generic',
		label: 'service.generic',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
	{
		code: 'vat',
		label: 'service.vat',
		group: {
			code: 'others',
			label: 'service_group.others',
		},
	},
];
