import { Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import {
	SERVICES_GROUPS,
	SERVICES_SHORT_TRANSLATION_KEY,
	TEMP_SERVICES,
	TRANSLATION_KEYS,
} from 'utils/constants';

const LegendForServiceType = () => {
	const { t: tDBLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	// TODO: temporary commented for next release
	// const { servicesMap } = useSelector((state: RootState) => state.claim);

	const servicesMap = TEMP_SERVICES;

	const services = servicesMap.map(service => {
		const initial = tDBLabels(
			`${service.label}.${SERVICES_SHORT_TRANSLATION_KEY}`,
		);

		if (service.group.code === SERVICES_GROUPS.OTHERS) {
			const other = tClientLabels(service.group.label);

			const serviceLabel = tDBLabels(service.label);

			const itemLabel = tClientLabels('service.legend.otherItem', {
				initial,
				other,
				service: serviceLabel,
			});

			return itemLabel;
		}

		return tClientLabels('service.legend.item', {
			initial,
			service: tDBLabels(service.label),
		});
	});

	return (
		<Stack direction={'row'}>
			<Typography variant="bodyLink" sx={{ whiteSpace: 'nowrap' }}>
				{tClientLabels('claim.legend.title')}
			</Typography>
			<Stack
				direction={'row'}
				sx={{ marginLeft: 2 }}
				flexWrap={'wrap'}
				gap={2}
				rowGap={1}
			>
				{services.map((service, index) => (
					<Typography key={index} variant="body">
						{service}
					</Typography>
				))}
			</Stack>
		</Stack>
	);
};

export default LegendForServiceType;
