import { ThunkApiType } from 'types/utils';
import authFetch, { checkForUnauthorizedResponse } from 'utils/axios';

export const getClaimsStatusesThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimsStatusesURL = '/claim/status';

	try {
		const response = await authFetch.get(getClaimsStatusesURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
